import * as yup from "yup";
import { Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import FormGroup from "./FormGroup";
import {
  FormSelect,
  FormAsyncSelect,
  FormSelectProps,
  FormAsyncSelectProps,
} from "./FormSelect";
import { client } from "../lib/client/client";
import { BucketList } from "../lib/api-types";

export interface S3FormProps {
  onSubmit: (values: { bucket: string; key: string }) => void;
  state: string;
  defaultValue: string;
  newObject?: boolean;
}

export default function S3Form({
  onSubmit,
  state,
  defaultValue,
  newObject,
}: S3FormProps) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        bucket: defaultValue,
        key: "",
      }}
      validationSchema={yup.object().shape({
        bucket: yup.string().required(),
        key: yup.string().required(),
      })}
    >
      {({ handleSubmit, values }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FormGroup<FormSelectProps<{ value: string }, string>>
            name="bucket"
            label="Bucket name"
            as={FormSelect}
            options={[
              { value: "masked-pii-blend360i" },
              { value: "unmasked-pii-blend360" },
              { value: "fresh-direct-bucket" },
              { value: "freshdirect-idc" },
              { value: "unlock-pii" },
              { value: "unlock-idc" },
              { value: "21stcentury-pii" },
              { value: "21stcentury-idc" },
              { value: "nanawall-pii" },
              { value: "nanawall-idc" },
            ]}
            getOptionLabel={(x) => x.value}
            getOption={(x) => (x ? { value: x } : null)}
          />
          {newObject ? (
            <FormGroup name="key" label="Object key" />
          ) : (
            <FormGroup<FormAsyncSelectProps<{ key: string }, string>>
              name="key"
              label="Object key"
              as={FormAsyncSelect}
              loadOptions={(inputValue: string) =>
                client<BucketList>(
                  "json",
                  "GET"
                )(
                  `/api/list-bucket?bucket=${values.bucket}&prefix=${inputValue}`
                )
              }
              getOptionLabel={(x) => x.key}
              getOptionValue={(x) => x.key}
              getOption={(value) => (value ? { key: value } : null)}
              isDisabled={!values.bucket}
            />
          )}
          <Button disabled={state === "loading"} type="submit" className="mb-3">
            {state === "loading" ? "Loading" : "Submit"}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
